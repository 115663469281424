import { useTranslation } from 'react-i18next'
import { Alert } from 'reactstrap'

function BlockEmailNotice() {
  const { t } = useTranslation()

  return (
    <Alert color={'danger'} className="m-0">
      <i className="mdi mdi-alert"></i>{' '}
      <span className="block sm:inline">
        {t(
          'Email sending is disabled for this account. If you feel this is an error please contact support.'
        )}
      </span>
    </Alert>
  )
}

export default BlockEmailNotice
