import Cookies from 'js-cookie'
import { useAuthUser } from 'next-firebase-auth'
import React, { createContext, useContext, useEffect, useState } from 'react'

import BlockEmailNotice from '../components/common/BlockEmailNotice'
import BlockedUserNotice from '../components/common/BlockUserNotice'
import {
  getUsageLimit,
  initializeMisc,
  updateUsage
} from '../lib/authUserHelper'
import { sendToLogDNA } from '../lib/helpers'
import PlatformHelperFactory from '../utils/PlatformHelper'
import { PLANS_ORDERED, purgeCache } from '../utils/common'
import { sendDataToCrisp, syncDataToBrevo } from '../utils/userDataSync'
import { useFirebase } from './FirebaseProvider'

const AuthContext = createContext()

let logoutProcessStarted = false,
  unsubscribe = null,
  oldUserData = null,
  oldPlan = null

function AuthProvider({ children }) {
  const [viewCountAndLimit, setViewCountAndLimit] = useState({
    viewCount: 0,
    limit: 1000
  })
  const [userData, setUserData] = useState(null)
  const authUser = useAuthUser()
  const { firebase } = useFirebase()

  function processLogout(e = {}) {
    if (logoutProcessStarted) return
    logoutProcessStarted = true
    firebase.logout().catch(console.error)
    authUser.signOut().catch(console.error)
    //Navigate to the login screen
    if (userData) {
      const platformHelper = new PlatformHelperFactory({ userData })
      platformHelper
        .getUrlAfterLogout()
        .then((url) => {
          setUserData(null)
          if (window.self !== window.top) {
            window.top.location.href = url
          } else {
            location.href = url
          }
        })
        .catch(console.error)
        .finally(() => {
          logoutProcessStarted = false
        })
    } else {
      sendToLogDNA(
        `Failed to get userData: ${authUser.id ?? 'No user id'} - ${
          e.message ?? 'No message'
        }`
      )
      const platform = localStorage.getItem('pl-login-platform')
      if (platform === 'shopify') {
        window.top.location.href = `https://admin.shopify.com/`
      } else if (platform === 'colorme') {
        window.location.href = `/colorme/login`
      } else {
        window.location.href = `/login`
      }
      logoutProcessStarted = false
    }
  }

  function onUserDataChanged(userData) {
    //Uninstalled
    if (userData.deletedAt) {
      processLogout({ message: 'Uninstalled' })
    }

    setUserData(userData)
  }

  useEffect(() => {
    if (!userData) return

    if (oldUserData && oldUserData.email !== userData.email) {
      authUser
        .getIdToken()
        .then((token) => {
          syncDataToBrevo(userData, true, oldUserData.email, token)
        })
        .catch(console.error)
    } else {
      sendDataToCrisp(userData.email, userData).catch()

      authUser
        .getIdToken()
        .then((token) => {
          syncDataToBrevo(userData, false, '', token)
        })
        .catch(console.error)
    }
    oldUserData = userData
  }, [userData])

  function isCurrentPlanMoreThan(target) {
    let activePlan = (userData.platform?.currentPlan ?? 'Free').toLowerCase()

    const currentRank = PLANS_ORDERED.find(
      (plan) => plan.name === activePlan
    ).rank
    const targetRank = PLANS_ORDERED.find(
      (plan) => plan.name === target.toLowerCase()
    ).rank

    return currentRank >= targetRank
  }

  async function initAuth() {
    const userData = await firebase.getUserData(authUser.id)
    setUserData(userData)
    firebase.setUserHandler(authUser, setUserData)
    //Set firebase listener
    unsubscribe = firebase.listenUserDataChanges(authUser.id, onUserDataChanged)

    if (!Cookies.get('impersonate-login')) initializeMisc(userData)
  }

  useEffect(() => {
    if (!authUser.clientInitialized) return

    if (authUser.id) {
      //Initialize auth
      initAuth().catch((e) => {
        processLogout(e)
      })
    } else {
      if (typeof unsubscribe === 'function') unsubscribe()
      //Navigate the user to login screen
      processLogout({ message: 'Cannot get authUser.id' })
    }
  }, [authUser])

  //Get usage limit at first load and every time the plan changes
  useEffect(() => {
    if (!userData?.platform?.currentPlan) return

    getUsageLimit(userData.userId).then(([usage, error]) => {
      if (!error) {
        setViewCountAndLimit(usage)

        //Update usage percent in the firebase
        updateUsage(userData.userId, usage?.usagePercent ?? 0).catch(
          console.error
        )

        //Purge cache everytime plan changes
        if (oldPlan && oldPlan !== userData.platform.currentPlan)
          purgeCache(userData.userId, false, true)

        oldPlan = userData.platform.currentPlan
      }
    })
  }, [userData?.platform?.currentPlan])

  return (
    authUser.id &&
    userData && (
      <AuthContext.Provider
        value={{
          userData,
          authUser,
          getIdToken: () => firebase.getIdToken(),
          currentPlan: userData?.platform?.currentPlan ?? 'Free',
          viewCountAndLimit,
          isCurrentPlanMoreThan
        }}
      >
        {userData.isSpam && <BlockEmailNotice />}
        <BlockedUserNotice userId={userData.userId} />
        {children}
      </AuthContext.Provider>
    )
  )
}

const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth }
